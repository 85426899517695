import { themeDefault } from "../../constants/themeSettings";

const disableClass = "opacity-30 disabled:cursor-not-allowed";

const btn_bg_color_hover = `${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`;

export const ButtonCommon = (props) => {
  const { title, isDisable, handleClick, style } = props;

  return (
    <button
      disabled={isDisable}
      className={`px-2 py-1 leading-none rounded text-white font-medium text-xs ${btn_bg_color_hover} ${
        isDisable && disableClass
      } ${style}`}
      onClick={handleClick}
    >
      {title}
    </button>
  );
};
