const EditableTextButton = (props) => {
  const { handleSaveClick, handleCancelClick } = props;

  return (
    <div>
      <button
        className="mr-2 border-none bg-slate-100 hover:bg-slate-200 drop-shadow-lg py-1 px-1"
        onClick={handleSaveClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
          />
        </svg>
      </button>
      <button
        className="border-none bg-slate-100 hover:bg-slate-200 drop-shadow-lg py-1 px-1"
        onClick={handleCancelClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};

export default EditableTextButton;
