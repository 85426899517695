export const APP_NAME = "Engaging Women Sport Officials: Interactive Toolkit for Sport Organisations";

export const UPDATE_STATUS = "Pending";
export const SUBMIT_STATUS = "Submitted";

export const VIEW_ACTION = "View";
export const EDIT_ACTION = "edit";

export const DISABLED_STATUS = "DISABLED";
export const ENABLED_STATUS = "ENABLED";

// Feedback constants
export const BTN_START = "Start the evaluation";
export const BTN_CONTINUE = "Continue the evaluation";
export const BTN_EDIT = "Edit";

export const LBL_SUBMITTED = "Submitted";
export const LBL_NOT_STARTED = "Not yet started";
export const LBL_PARTIAL = "Partial";

export const BTN_NEWFEEDBACK_TITLE = "Start a new self-assessment now";
export const BTN_MAPPING_TITLE = "Access my previous self-assessments";

export const ERROR_PRIVACY_POLICY = "Please accept privacy policy to proceed.";
export const ERROR_CAPTCHA_CHECK = "reCaptcha verification failed, please try again!";

// Database table names used in api call
// Database table names used in api call
export const TABLE_THEME = "theme";
export const TABLE_SUB_THEME = "sub_theme";
export const TABLE_QUESTION = "question";

export const ALERT_TYPE_SUCCESS = "success";
export const ALERT_TYPE_ERROR = "error";

// User Roles
export const USER_ROLE_ADMIN = "admin";
export const USER_ROLE_TEST = "tester";

export const STICK_VALUES = [0, 1, 2, 3];
export const STICK_TITLE = [
  { title: "Strongly Disagree", style: "col-span-4" },
  { title: "Disagree", style: "grid-cols-end col-span-3" },
  { title: "Agree", style: "col-span-3 pl-7" },
  { title: "Strongly Agree", style: "col-span-2 pl-4" },
];

// COLORS w.r.t Pillars sequence
/* export const PILLAR_BG_COLORS = [
  "bg-sky-800",
  "bg-lime-800",
  "bg-indigo-800",
  "bg-gray-800",
]; */

export const PILLAR_BG_COLORS = ["#73ebb6", "#eb9a5b", "#4480eb", "#eb6650"];
export const PILLAR_HASH_COLORS = [
  "#075985",//"#085985",
  "#3F6212",//"#3f6213",
  "#3730a3",//"#3730a3",
  "#1f2937",//"#1f2a37",
];

export const PILLAR_TEXT_COLORS = [
  "text-sky-800",
  "text-lime-800",
  "text-indigo-800",
  "text-gray-900",
];
