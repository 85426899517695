const ShowAlertIcon = (message) => {
  const successIcon = () => {
    return (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        class="w-8 h-8 text-white"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    );
  };

  const errorIcon = () => {
    return (
      <svg
        class="h-8 w-8 text-red-500"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" /> <path d="M12 9v2m0 4v.01" />{" "}
        <path d="M5.07 19H19a2 2 0 0 0 1.75 -2.75L13.75 4a2 2 0 0 0 -3.5 0L3.25 16.25a2 2 0 0 0 1.75 2.75" />
      </svg>
    );
  };

  const showIcon = () => {
    if (message === "success") {
      return successIcon();
    }
    return errorIcon();
  };

  const alertBgColor = (message) => {
    return `${message === "success" ? "bg-green-500" : "bg-red-300"}`;
  };
  const alertTextColor = (message) => {
    return `${message === "success" ? "text-green-500" : "text-red-500"}`;
  };
  const alertText = (message) => {
    return `${message === "success" ? "Success" : "Error"}`;
  };

  return (
    <div className="flex justify-center">
      <div class="flex justify-center bg-white shadow rounded-md mt-6 px-2 m-8 w-fit">
        <div
          class={`mr-6 rounded px-4 py-2  text-center -ml-3 ${alertBgColor(
            message
          )}`}
        >
          {showIcon(message)}
        </div>
        <div class="flex items-center">
          <h2 class={`text-2xl font-bold mr-2 ${alertTextColor(message)}`}>
            {alertText(message)}
          </h2>
        </div>
      </div>
    </div>
  );
};

const BackdropLoader = (props) => {
  const { title, isLoading, isOpen, message, severity, onClose } = props;

  return (
    <div>
      <div className={`modal ${isOpen && "modal-open"}`}>
        <div className="modal-box max-w-5xl">
          {severity && ShowAlertIcon(severity)}
          <h3 className="font-bold text-lg text-slate-400">
            {message || "Loading..."}
            <p className="text-sky-700"> {title} </p>
          </h3>
          {isLoading && (
            <div className="mt-5">
              <progress className="progress w-56"></progress>
            </div>
          )}
          {!isLoading && (
            <div className="modal-action p-6 border-t border-solid border-blueGray-200">
              <button
                className={`btn btn-outline btn-success ${
                  severity === "error" && "btn-error"
                }`}
                onClick={onClose}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default BackdropLoader;
