import { Routes, Route, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Registraion from "./pages/Login/Signup";
import Home from "./pages/Index";
import Signup from "./pages/Login/Signup";
import LoginPage from "./pages/Login";
import NavBar from "./components/NavBar";
import Wizard from "./components/Wizard";
import WizardNew from "./components/FeedbackWizard";
import { RequireAuth } from "./components/RequireAuth";
import ProtectedAdminRoute from "./components/ProtectedAdminRoutes";
import History from "./pages/History";
import About from "./pages/About";
import Feedback from "./pages/Feedback/Feedback";
import NewFeedback from "./pages/NewFeedback/NewFeedback";
import ForgotPassword from "./pages/Login/ForgotPassword";
import React, { useEffect } from "react";
import { getUser, isAdminRole } from "./service/Auth";
import Admin from "./pages/AdminDashboard/adminDashboard";
import HistoryIndex from "./pages/History/HistoryIndex";
import {
  ABOUT_ROUTE,
  FEEDBACK_ID_ROUTE,
  FEEDBACK_ROUTE,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
  WIZARD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  QUESTIONS_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  USER_REGISTRATION_ROUTE,
  FEEDBACK_ROUTE_NEW,
  PROFILE_ROUTE,
} from "./constants/Routes";
import { APP_NAME } from "./constants";
import { APP_IMAGE_LOGO } from "./images";
import { QuestionContainer } from "./pages/Question";
import NewWizard from "./components/NewWizard";
import UserRegistration from "./pages/UserRegistration";
import ProfilePage from "./components/Profile/Profile";

function App() {
  const user = getUser();
  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);
  useEffect(() => {
    if (user === "null") {
      navigateTo("/login");
      return;
    }
  }, [user]);
  return (
    <div className='App'>
      <header className='App-header bg-white sticky top-0 z-50'>
        <div className='w-full flex items-center justify-between p-3 text-sm font-medium text-gray-800 border-b shadow-lg h-16'>
          <div
            className={`text-xl font-semibold text-center rounded-md py-1 px-3 hover:bg-sky-100 hover:text-sky-700`}
          >
            <NavLink to='/'>
              <div class='flex flex-row gap-2 items-center'>
                <img
                  src={APP_IMAGE_LOGO}
                  alt='application logo'
                  class='h-10 pr-1 mx-auto'
                />
                <h1>{APP_NAME}</h1>
              </div>
            </NavLink>
          </div>
          <NavBar />
        </div>
      </header>
      <div className='min-h-full w-full flex justify-center'>
        <Routes>
          <Route element={<RequireAuth />}>
            <Route path='/' element={<Dashboard />} exact />
            {/* <Route path={`${WIZARD_ROUTE}`} element={<WizardNew />} /> */}
            <Route path={`${WIZARD_ROUTE}`} element={<NewWizard />} />
            <Route
              path={`${PROFILE_ROUTE}`}
              element={<ProfilePage getUser={getUser} />}
            />
            <Route path={`${FEEDBACK_ROUTE}`} element={<HistoryIndex />} />
            <Route path={`${FEEDBACK_ID_ROUTE}`} element={<NewFeedback />} />
            <Route path={`${ABOUT_ROUTE}`} element={<About />} />
            {/* {isAdminRole() && (
              <Route
                path={`${USER_REGISTRATION_ROUTE}`}
                element={<UserRegistration />}
              />
            )} */}
          </Route>

          <Route path='/admin' element={<ProtectedAdminRoute />}>
            <Route path={`${ADMIN_DASHBOARD_ROUTE}`} element={<Admin />} />
            {/* <Route
              path={`${QUESTIONS_ROUTE}`}
              element={<QuestionContainer />}
            /> */}
            <Route
              path='*'
              element={
                <main style={{ padding: "1rem" }}>
                  <p>Page not found !</p>
                </main>
              }
            />
          </Route>

          <Route path={`${LOGIN_ROUTE}`} element={<LoginPage />} />
          <Route path={`${SIGNUP_ROUTE}`} element={<Signup />} />
          <Route
            path={`${FORGOT_PASSWORD_ROUTE}`}
            element={<ForgotPassword />}
          />

          <Route
            path='*'
            element={
              <main style={{ padding: "1rem" }}>
                <p>Page not found !</p>
              </main>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
