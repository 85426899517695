import { PILLAR_TEXT_COLORS, PILLAR_BG_COLORS } from "../../constants/index";

const STICK_VALUES = [0, 1, 2, 3, 4];
const STICK_TITLE = [
  { title: "No answer", style: "col-span-2 pl-[60px]" },
  { title: "Strongly Disagree", style: "col-span-2 pl-[40px]" },
  { title: "Disagree", style: "col-span-2 pl-[75px]" },
  { title: "Agree", style: "col-span-2 pl-[91px]" },
  { title: "Strongly Agree", style: "col-span-2  pl-[65px]" },
];

const Slider = ({
  handleChange,
  selectedValue = 0,
  isDisable,
  style = {},
  activeStepIndex,
}) => {
  //const [check, setCheck] = useState(selectedValue);

  return (
    <div className={`flex flex-col items-center ${style}`}>
      <div className={`w-80 ${style}`}>
        <input
          type='range'
          min='0'
          max={STICK_VALUES.length - 1}
          value={selectedValue}
          className={`range range-xs ${
            (activeStepIndex === 0 && "range-primary") ||
            (activeStepIndex === 1 && "range-secondary") ||
            (activeStepIndex === 2 && "range-accent") ||
            (activeStepIndex === 3 && "range-info")
          } ${isDisable && "cursor-not-allowed"}`}
          step=''
          onChange={handleChange}
          disabled={isDisable}
        />
      </div>
      <div class='w-full text-sm text-slate-700 grid grid-cols-10 content-end my-2.5'>
        {STICK_VALUES.map((val) => {
          return (
            <span
              className={`${
                parseInt(selectedValue, 10) === val &&
                PILLAR_TEXT_COLORS[activeStepIndex]
              } font-bold ${STICK_TITLE[val].style}`}
              style={{
                color:
                  parseInt(selectedValue, 10) === val &&
                  PILLAR_BG_COLORS[activeStepIndex % PILLAR_BG_COLORS.length],
              }}
            >
              {STICK_TITLE[val].title}
            </span>
          );
        })}
      </div>
     </div>
  );
};

export default Slider;
