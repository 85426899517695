import React from "react";

const Checkbox = (props) => {
  const { options = [], onChange, selectedOption = [], isDisable } = props;

  return (
    <div className="pr-7">
      <fieldset>
        {options.map((obj) => {
          return (
            <div key={obj.id} className="flex items-center">
              <input
                id={obj.id}
                name={obj.title}
                aria-describedby={obj.id}
                type="checkbox"
                disabled={isDisable}
                className={`bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded ${
                  isDisable && "cursor-not-allowed"
                }`}
                checked={selectedOption.includes(obj.title)}
                onChange={onChange}
              />
              <label
                for="checkbox-1"
                className="text-sm ml-3 font-medium text-gray-900 text-base"
              >
                {obj.title}
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default Checkbox;
