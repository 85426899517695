import { APP_IMAGE_BACKGOUND } from "../../images"

const BgImage = () => {
    return (
        <img
            src={APP_IMAGE_BACKGOUND}
            className="object-cover w-full h-full"
            alt="introduction page image"
        />
    )
}
export default BgImage