import React, { useState, useEffect } from "react";
import { BACKEND_DATA } from "../../data";
//import { getTopics, getGroups } from "../../service";
import { LoadingSkeleton } from "../common/LoadingSkeleton";

import Card from "./Card";

import useApiCallOnMount from "../hoc/useApiCallOnMount";

import ErrorPage from "../common/ErrorPage";
import Topic from "../Feedback/Topic";
import Category from "../Feedback/Category";
import Group from "../Feedback/Group";
import Feedback from "./Feedback";
import FeedbackForm from "./FeedbackForm";
import { useNavigate } from "react-router-dom";
import { getUser, clearUser } from "../../service/Auth";

const STEPS = [
  {
    id: "topic",
    title: "Topic",
    description: "What is the topic of your project",
  },
  {
    id: "group",
    title: "Group",
    description: "Choose the relevant stackholder group for the topic at hand",
  },
  {
    id: "category",
    title: "Category",
    description: "What are the political actors for your project?",
  },
  {
    id: "feedback",
    title: "Feedback",
    description: "What is your feedback for selected category?",
  },
];

const formatDataForWizard = (data) => {
  const formatedData = data;
  return formatedData;
};

const Wizard = (props) => {
  const { selectedFormData } = props;
  const user = getUser();
  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);

  //  Use HOC 'useApiCallOnMount' to call api on mount
  // const [isLoading, data = [], isError] = useApiCallOnMount(getTopics);
  const [isLoading, setLoader] = useState(false);
  const [isError, setError] = useState("");
  const [data, setData] = useState([]);
  const [groupsList, setGroupsList] = useState([]);

  const [formData, setFormData] = useState(selectedFormData || {});

  const [activeStep, setActiveStep] = useState(0);
  const [stepName, setActiveStepName] = useState("topic");
  const totalSteps = STEPS.length;
  /* 
  useEffect(() => {
    setLoader(true);
    getTopics()
      .then((data) => {
        if (!data.errorData) {
          setData(data);
          setLoader(false);
        } else throw new Error("Something went wrong");
      })
      .catch(() => {
        setLoader(false);
        setError(
          "Error occured while fetching Topic data ! Please contact to admin."
        );
      });

    // Fetch groups data
    getGroups()
      .then((data) => {
        if (!data.errorData) {
          setGroupsList(data);
          setLoader(false);
        } else throw new Error("Something went wrong");
      })
      .catch(() => {
        setLoader(false);
        setError(
          "Error occured while fetching Groups data ! Please contact to admin."
        );
      });
  }, []); */

  const handleStepChange = (val) => {
    setActiveStep(val);
    setActiveStepName(STEPS[val].id);
  };

  const handleSelection = (val) => {
    console.log("handleSelection", val);
    setFormData({ ...formData, [stepName]: val });
  };

  useEffect(() => {
    if (user === "null") {
      clearUser();
      navigateTo("/login");
      return;
    }
  }, [user]);

  if (isError) {
    return <ErrorPage />;
  }

  const getComponent = () => {
    // Filter data to get selected object for topic
    const selectedTopicData = data.filter((f) =>
      formData ? f.title === formData["topic"] : {}
    );

    console.log("Form State ", formData);
    return (
      <div>
        <div id="card-title" className="font-bold text-xl mb-2 text-center">
          <h2>{STEPS[activeStep].title}</h2>
        </div>
        <div className="m-5">{STEPS[activeStep].description}</div>
        {(() => {
          switch (STEPS[activeStep].id) {
            case "topic":
              return (
                <Topic
                  selectedOption={formData["topic"]}
                  data={data}
                  handleSelection={handleSelection}
                />
              );
            case "group":
              return (
                <Group
                  defaultSelected={formData["group"]}
                  data={selectedTopicData[0]}
                  handleSelection={handleSelection}
                />
              );
            case "category":
              return (
                <Category
                  selectedCategory={formData["category"]}
                  selectedGroups={formData["group"]}
                  data={selectedTopicData[0]}
                  handleSelection={handleSelection}
                />
              );
            case "feedback":
              return <Feedback data={data} handleSelection={handleSelection} />;
            default:
              return null;
          }
        })()}
      </div>
    );
  };

  // This is card view form
  const cardViewForm = () => {
    return (
      <>
        <p className="bg-slate-200 glass mx-10">Step {activeStep}</p>
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <Card
            cardComponent={getComponent()}
            totalSteps={totalSteps}
            activeStep={activeStep}
            stepsData={STEPS[activeStep]}
            onStepChange={handleStepChange}
          />
        )}
      </>
    );
  };

  const singleFormView = () => {
    return (
      <FeedbackForm
        data={data}
        defaultFormData={formData}
        groupsList={groupsList}
      />
    );
  };

  return (
    <div className="w-full py-10 px-4 sm:px-6 lg:px-8 ">
      {isLoading ? <LoadingSkeleton /> : singleFormView()}
    </div>
  );

  // This is card view form
  return <div className="w-11/12"></div>;
};

export default Wizard;
