import React from "react";
import ReactLogo from "./../../images/footerLogo.svg";

const AppFooter = () => {
  return (
    <div className="flex hs-1/4">
      <div className="bg-[#1e4798eb] md:px-32 px-8 py-1">
        {/* <div className="flex justify-center space-x-4 items-center w-auto pt-12 pb-6 sm:flex-col"> */}
        <div className="flex flex-col md:flex-row space-x-4 space-y-2 items-center m-3">
          <div className="w-60 h-auto bg-white">
            <img
              width={"100%"}
              height={"100%"}
              src={ReactLogo}
              alt="React Logo"
            />
          </div>
          <div className="w-3/4 text-white italic text-left">
            This project has been funded with support from the European
            Commission. This publication reflects the views only of the author,
            and the Commission cannot be held responsible for any use which may
            be made of the information contained therein.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
