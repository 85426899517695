const Modal = (props) => {
  const {
    modalTitle,
    title,
    subTitle,
    isOpen,
    childComponent,
    modalStyle,
    confirmBtntext,
    cancelBtntext,
    saveBtnStyle,
    cancelBtnStyle,
    onConfirm,
    onClose,
    hideActionButtons,
  } = props;

  return (
    <div>
      <div class={`modal ${isOpen && "modal-open"}`}>
        <div class={`modal-box max-w-7xl ${modalStyle}`}>
          <label
            for="my-modal-3"
            class="btn btn-sm hover:btn-error btn-circle btn-outline sticky float-right top-0"
            onClick={onClose}
          >
            ✕
          </label>
          <h3 class="font-semibold text-xl pl-8">{modalTitle}</h3>
          <p className="font-semibold text-md text-sky-700 mt-3">
            {" "}
            {title || ""}{" "}
          </p>
          {/* <p className="text-slate-400"> {subTitle || ""} </p> */}

          <div className="mt-7">{childComponent}</div>
          {!hideActionButtons && (
            <div className="modal-action p-6 border-t border-solid border-gray-300">
              <button class={`btn btn-outline ${saveBtnStyle ? saveBtnStyle : 'btn-success'}`} onClick={onConfirm}>
                {confirmBtntext || 'Save'}
              </button>
              <button class={`btn btn-outline ${cancelBtnStyle ? cancelBtnStyle : 'btn-error'}`} onClick={onClose}>
                {cancelBtntext || 'Cancel'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Modal;
