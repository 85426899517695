const NO_OF_ROWS = 5;

export const TableSkeleton = () => {
  return (
    <div>
      <div className="border border-blue-300 shadow rounded-md p-4 max-w-l w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-700 rounded"></div>
            <div className="space-y-3">
              {[...Array(NO_OF_ROWS)].map((elem, index) => (
                <div className="grid grid-cols-4 gap-12">
                  <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                  <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                  <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                  <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
