import { useState, useEffect } from "react";
import moment from "moment";
import {
  EDIT_ACTION,
  SUBMIT_STATUS,
  UPDATE_STATUS,
  VIEW_ACTION,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import UpArrow from "../common/UpArrow";
import DownArrow from "../common/DownArrow";
import { themeDefault } from "../../constants/themeSettings";

// show maximum no of array element/string in cell.
const NO_OF_ARRAY_ELE_SHOW = 3;
const NO_OF_ARRAY_ELE_SHOW_SUB_THEME = 2;

const btn_bg_color_hover = `${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`

const Table = (props) => {
  const {
    columns,
    data,
    actionType,
    onRowClick,
    onSearchChange,
    searchText,
    tableFilter,
    sortColumn,
    sortOrder,
    handleSetSortOrder,
    handleGraphClick,
  } = props;

  let navigate = useNavigate();

  const handleActionClick = (status, data, actionType) => {
    onRowClick(data, actionType);
  };

  console.log("Table", data);

  const getFormEditActionIcon = (row) => {
    if (actionType.includes("view")) {
      const disableClass = "opacity-30 disabled:cursor-not-allowed";
      const isDisable = false; // Add condition on for which button will be disabled
      const canEdit = row.status === UPDATE_STATUS; // Disable edit for submitted forms.

      return (
        <>
          <button
            type="button"
            // onClick={() => handleActionClick(row.status, row, VIEW_ACTION)}
            onClick={() => navigate(`/feedback/${row.objectId}`)}
            className={`w-[8rem] px-1 py-2 leading-none rounded text-white font-semibold text-xs ${btn_bg_color_hover} ${isDisable && disableClass
              }`}
            disabled={isDisable}
          >
            {canEdit ? "Edit your response" : "View my answers"}
          </button>
          {
            <button
              onClick={() => handleGraphClick(row)}
              className={`w-[8rem] px-1 py-2 leading-none rounded text-white font-semibold text-xs ${btn_bg_color_hover} ${row.status !== SUBMIT_STATUS && disableClass
                }`}
              disabled={row.status !== SUBMIT_STATUS}
            >
              View my results
            </button>
          }
        </>
      );

      return (
        <>
          <button
            type="button"
            onClick={() => handleActionClick(row.status, row, VIEW_ACTION)}
            class={`px-3 py-2 leading-none text-white bg-sky-700 rounded focus:outline-none ${isDisable && disableClass
              }`}
            disabled={isDisable}
          >
            View
          </button>

          <button
            type="button"
            onClick={() => handleActionClick(row.status, row, EDIT_ACTION)}
            class={`px-3 py-2 leading-none text-white bg-slate-700 rounded focus:outline-none ${(isDisable || !canEdit) && disableClass
              }`}
            disabled={isDisable || !canEdit}
          >
            Edit
          </button>
        </>
      );
    }
  };

  const showTableFilterBlock = () => {
    return (
      <div className="flex flex-col justify-end items-end my-4">
        <label htmlFor="search">
          <input
            type="text"
            id="search"
            className="bg-white-50 border border-gray-300 text-sm rounded-lg block w-80 pl-10 p-2.5 border-gray-200 placeholder-gray-400"
            placeholder="Quick Search..."
            value={searchText}
            onChange={onSearchChange}
          />
        </label>
        <p className="text-sm text-slate-400 p-1">
          *Please use at least 3 characters
        </p>
      </div>
    );
  };

  //? Object.keys(row.groups) : []

  const showSubThemeCell = (row) => {
    const groupsArr = row.subTheme;

    if (!groupsArr || !groupsArr.length) return noDataText();

    return (
      <span>
        {groupsArr.length > NO_OF_ARRAY_ELE_SHOW_SUB_THEME
          ? `${groupsArr
            .slice(0, NO_OF_ARRAY_ELE_SHOW_SUB_THEME)
            .join(", ")}, ...`
          : groupsArr.join(", ")}
      </span>
    );
  };

  const showThemeCell = (row) => {
    const groupsArr = row.theme;

    if (!groupsArr || !groupsArr.length) return noDataText();

    return (
      <span>
        {groupsArr.length > NO_OF_ARRAY_ELE_SHOW
          ? `${groupsArr.slice(0, NO_OF_ARRAY_ELE_SHOW).join(", ")}, ...`
          : groupsArr.join(", ")}
      </span>
    );
  };
  const showStatusCell = (row) => {
    const status = row.status;
    if (!status) return noDataText();

    const statyStyle = status === UPDATE_STATUS ? "bg-red-200" : "bg-green-200";

    return (
      <span className={`inline-block w-20 p-2 rounded-md ${statyStyle}`}>
        {status}
      </span>
    );
  };

  const noDataText = () => {
    return <span className="text-slate-400">---</span>;
  };

  return (
    <div class="p-5 bg-gray-50">
      <h1 class="text-xl font-medium mb-2">Your Assessments</h1>
      {showTableFilterBlock()}
      <div class="overflow-x-auto rounded-lg shadow">
        <table class="w-full">
          <thead class="bg-gray-100 border-b-2 border-gray-200 ">
            <tr>
              {columns.map((col) => {
                return (
                  <th
                    class="w-24 p-3 text-sm font-semibold tracking-wide text-centre"
                    onClick={() => handleSetSortOrder(col)}
                  >
                    <div
                      className={`flex flex-row justify-center items-center text-sm font-medium p-1 ${col.sortType && "cursor-pointer hover:text-sky-700"
                        } ${col.sortType &&
                        col.title === sortColumn.title &&
                        "text-sky-700"
                        }`}
                    >
                      {col.title.toUpperCase()}
                      {col.sortType &&
                        (sortOrder === "desc" ? <DownArrow /> : <UpArrow />)}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-100">
            {data.map((row) => {
              return (
                <tr class="bg-white odd:bg-white even:bg-slate-50 transition ease-in-out hover:-translate-y-1 hover:scale-5 hover:bg-indigo-100">
                  <td class="p-3 text-sm text-gray-700">
                    {moment(new Date(row.created)).format(
                      "DD/MM/YYYY HH:mm a"
                    ) || noDataText()}
                  </td>
                  <td class="p-3 text-sm text-gray-700">
                    {/* {row.theme || noDataText()} */}
                    {showThemeCell(row)}
                  </td>
                  <td class="p-3 text-sm text-gray-700">
                    {/*  {row.subTheme || noDataText()} */}
                    {showSubThemeCell(row)}
                  </td>
                  {/* <td class="p-3 text-sm text-gray-700">
                    {noDataText()}
                  </td> */}
                  <td class="p-3 text-sm text-gray-700">
                    {showStatusCell(row)}
                  </td>
                  <td class="text-sm text-gray-700 space-x-2">
                    {getFormEditActionIcon(row)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Table;
