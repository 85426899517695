import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

const TopicSelect = (props) => {
  const { data, selectedOption, isDisable, onChange } = props;
  const [values, setValues] = useState(data);

  return (
    <div className="dropdown-container text-base text-left w-96">
      <CreatableSelect
        isDisabled={isDisable}
        placeholder="Select or Type to create a new topic..."
        onChange={onChange}
        options={data.map((str, index) => {
          return {
            label: str.name,
            value: str.name,
            key: index,
          };
        })}
        value={
          selectedOption
            ? {
                label: selectedOption,
                value: selectedOption,
              }
            : null
        }
        hideSelectedOptions={false}
      />
    </div>
  );
};

export default TopicSelect;
