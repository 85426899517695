import { themeDefault } from "../../constants/themeSettings";

const disableClass = "opacity-30 disabled:cursor-not-allowed";

const btn_bg_color_hover = `${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`;

export const ButtonOutline = (props) => {
  const { title, isDisable, onClick, isActive, style } = props;

  return (
    <button
      disabled={isDisable}
      className={`px-2 ${!isActive && 'bg-transparent'} font-medium ${isActive ? 'text-white' : themeDefault.text_color_dark} hover:text-white border border-[${themeDefault.dark_sky}] rounded ${btn_bg_color_hover} ${isDisable && disableClass} ${style}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
