import Header from "../../components/User/Header";
import Login from "../../components/User/Login";
import BgImage from "./BgImage";

const loginBlock = (second) => {
  return (
    <>
      <Header
        heading="Login to your account"
        paragraph="Don't have an account yet? "
        linkName="Signup"
        linkUrl="/signup"
      />
      <Login />
    </>
  );
};

export default function LoginPage() {
  return (
    <div className="lg:flex w-full">
      <div className="hidden lg:flex bg-indigo-100 flex-1 h-screen">
        <BgImage />
      </div>
      <div className="lg:w-6/12 xl:h-screen flex items-top justify-center">
        <div className="px-12 sm:px-24 md:px-48 lg:px-12 xl:px-20 xl:max-w-2xl w-full">
          {loginBlock()}
        </div>
      </div>
    </div>
  );
}
