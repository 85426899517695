import { useState, useEffect, useRef } from "react";
import EditableTextButton from "../common/EditableTextButton";
const EditableText = (props) => {
  const {
    disabled,
    autoFocus,
    name,
    id,
    placeholder,
    handleTextChange,
    handleOnEnterSave,
    value,
    style,
    index,
    activeInputBox,
    setActiveInputBox,
    description = false,
    title = false,
  } = props;

  const [originalValue, setOriginalValue] = useState("");

  const textAreaRef = useRef(null);

  const inputRef = useRef(null);

  const handleSaveClick = (e) => {
    e.preventDefault();
    handleOnEnterSave(textAreaRef.current.value);
    setOriginalValue(textAreaRef.current.value);
    setActiveInputBox(null);
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    if (originalValue !== "") {
      handleTextChange(originalValue);
    }

    setActiveInputBox(null);

    // Discard changes and exit edit mode
  };

  useEffect(() => {
    // Function to handle outside click
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        //setOriginalValue("");
        setActiveInputBox(null);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [inputRef]);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };

  useEffect(resizeTextArea, [value]);

  const setRefs = (element) => {
    textAreaRef.current = element;
    inputRef.current = element;
  };

  const onChange = (e) => {
    handleTextChange(e.target.value);
    if (value !== e.target.value && originalValue === "") {
      setOriginalValue(value);
    }
  };

  const onKeyEnter = (e) => {
    //console.log("on ENter call api for ", e.target.value);
    if (e.which === 13) {
      handleOnEnterSave(e.target.value);
      setOriginalValue(e.target.value);
      setActiveInputBox(null);
      e.preventDefault();
    }
  };

  const handleClick = () => {
    setActiveInputBox(id);
  };

 const handleOnBlur = (e) => {
   setTimeout(() => {
     if (activeInputBox == null) {
       return null;
     } else if (e.target.value !== originalValue && originalValue !== "") {
       handleOnEnterSave(e.target.value);
       setOriginalValue(e.target.value);
       setActiveInputBox(null);
     }
   }, 500);
 };


  return (
    <>
      <textarea
        autoFocus={autoFocus}
        ref={textAreaRef}
        value={value}
        onBlur={handleOnBlur}
        onKeyDown={onKeyEnter}
        onClick={handleClick}
        onChange={onChange}
        rows={1}
        cols={10}
        spellCheck='false'
        style={{
          resize: "none",
          padding: "20",
          overflowY: "hidden",
          width: title ? "91.666667%" : "100%",
          left: title ? "84px" : "0px",
          paddingLeft: title ? "5px" : "8px",
          // marginBottom: index === 0 || title ? "26px" : "24px",
          /* height: "120px", */
          /*  maxWidth: "600px",
          minWidth: "400px", */
        }}
        disabled={disabled}
        name={name}
        id={id}
        placeholder={placeholder}
        className={`relative pt-1 mx-1 focus:bg-white outline-none resize-none focus:border-b-2 focus:border-blue-500 
                ${style}`}
      />
      {activeInputBox === id && (
        <div
          className='absolute right-0 -bottom-9'
          ref={inputRef}
          style={{
            // bottom: description ? "-10px" : null,
            // right: title ? "10px" : "0px",
            // top: title ? "80px" : null,
            // zIndex: title ? "5" : null,
            zIndex: 10
          }}
        >
          <EditableTextButton
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
          />
        </div>
      )}
    </>
  );
};

export default EditableText;
