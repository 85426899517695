import { themeDefault } from "../../constants/themeSettings";
import { PILLAR_BG_COLORS } from "../../constants/index";

const TextCard = (props) => {
  const { step, index, data = [], activeStepIndex = 0, handleOnStepClick, stepCompletPercentage } = props;

  if (!data || !data.length) return null;

  const hoverTans = "transform transition duration-500 hover:scale-105";
  const selectedColor = "bg-sky-800 text-white";

  const opacityDark = "opacity-100";
  const opacityLight = "opacity-30";

  // Return true for current selected step
  const isCurrentStep = (index) => {
    return index === activeStepIndex;
  };

  const handleOnClick = (stepIndex, stepData) => {
    handleOnStepClick(stepIndex, stepData);
  };

  const isPillarCompleted = (stepCompletPercentage) => {
    const isCompleted = stepCompletPercentage === 100;
    if (isCompleted) return "text-green-700";

    return "text-gray-200";
  };

  return (
    <div>
      {
        <div
          className={`px-4 font-semibold py-1 relative`}
          onClick={() => handleOnClick(index, step)}
        >
          <div
            className={`${themeDefault.text_color_dark} ${hoverTans} ${
              isCurrentStep(index) ? opacityDark : opacityLight
            }  text-white bg-white shadow border rounded-md p-2 w-64`}
            style={{
              backgroundColor:
                PILLAR_BG_COLORS[index % PILLAR_BG_COLORS.length],
            }}
          >
            <h5 class='text-lg font-light mb-1.5 mt-0'>PILLAR</h5>
            <h5 class='text-3xl font-bold mb-1.5 mt-0'>0{index + 1}</h5>
            <p class='text-lg'>{step.title}</p>
          </div>
          {
            <div
              className={`animate-bounces rounded h-1.5 ${
                isCurrentStep(index) ? opacityDark : opacityLight
              }`}
              style={{
                backgroundColor:
                  PILLAR_BG_COLORS[index % PILLAR_BG_COLORS.length],
              }}
            ></div>
          }
          {stepCompletPercentage > 0 && (
            <>
              <div
                className={`absolute right-6 top-8 text-sm text-white ${isPillarCompleted(
                  stepCompletPercentage
                )}`}
              >
                {stepCompletPercentage}%
              </div>
              <div class='absolute right-6 top-2'>
                <svg
                  class={`h-6 w-6 ${isPillarCompleted(stepCompletPercentage)}`}
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                >
                  {" "}
                  <polyline points='9 11 12 14 22 4' />{" "}
                  <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11' />
                </svg>
              </div>
            </>
          )}
        </div>
      }

      {/* {<div className="bg-sky-700 h-2 mx-4"></div>} */}
    </div>
  );
};
export default TextCard;
