import Header from "../../components/User/Header";
import ForgotPassword from "../../components/User/ForgotPassword";
import { APP_NAME } from "../../constants";
import BgImage from "./BgImage";

const forgotpasswordBlock = () => {
    return (
        <>

            <Header
                heading="Forgot your password?"
                paragraph={`Enter your email address and we'll send you a link to create a new password via email`}
                linkName="Back to Login"
                linkUrl="/"
            />
            <ForgotPassword />
        </>
    );
};

export default function ForgotPasswordPage() {
    return (
        <div className="lg:flex w-full">
            <div className="hidden lg:flex bg-indigo-100 flex-1 h-screen">
                <BgImage />
            </div>
            <div className="lg:w-6/12 xl:h-screen flex items-top justify-center">
                <div className="px-12 sm:px-24 md:px-48 lg:px-12 xl:px-20 xl:max-w-2xl w-full">
                    {forgotpasswordBlock()}
                </div>
            </div>
        </div>
    );
}
