import { PDFDocument } from "pdf-lib";
import axios from "axios";

export const getMergedPdf = async (pdfUrlArr = []) => {
  // console.log("pdfUrlArr ", pdfUrlArr);
  const pdfOne = pdfUrlArr[0] || "";
  const pdfTwo = pdfUrlArr[1] || "";
  let mergedPdfUrl = "";

  const requestOne = axios({
    method: "GET",
    url: pdfOne,
    responseType: "arraybuffer",
  });
  const requestTwo = axios({
    method: "GET",
    url: pdfTwo,
    responseType: "arraybuffer",
  });

  let endpoints = [requestOne, requestTwo];

  await axios.all(endpoints).then(
    axios.spread(async (...responses) => {
      const arrayBuffer = [];
      responses.forEach(async (response) => {
        const pdfDoc = PDFDocument.load(response.data);
        arrayBuffer.push(pdfDoc);
      });

      setTimeout(async () => {
        const pdfDocuments = await Promise.all(arrayBuffer);
        const mergedPdf = await PDFDocument.create();

        for (const pdf of pdfDocuments) {
          const copiedPages = await mergedPdf.copyPages(
            pdf,
            pdf.getPageIndices()
          );

          copiedPages.forEach((page) => {
            mergedPdf.addPage(page);
          });
        }

        const mergedPdfFile = await mergedPdf.save({ addDefaultPage: false });
        console.log("mergedPdfFile", mergedPdfFile);
        const mergedPdfBlob = new Blob([mergedPdfFile], {
          type: "application/pdf",
        });

        mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);
        console.log("mergedPdfUrl", mergedPdfUrl);
        window.open(mergedPdfUrl, "_blank");
      }, 1000);
    })
  );
};
