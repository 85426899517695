export const themeDefault = {
  // Tailwind classes
  text_small: "text-sm",
  text_base: "text-base",
  text_large: "text-lg",
  text_x_large: "text-xl",
  text_2x_large: "text-2xl",

  // Font size
  font_md: "1.7em",
  font_l: "1.7em",
  font_xl: "2em",

  // Colors
  blue: "#1fb6ff",
  dark_sky: "#215B85",
  pink: "#ff49db",
  orange: "#ff7849",
  green: "#13ce66",
  gray: "#8492a6",
  grayDark: "#273444",
  grayLight: "#8b98a6",

  // Buttons
  btn_slate: "bg-slate-300",
  btn_green: "bg-green-200",
  btn_blue: 'bg-[#215B85]',
  btn_light_blue: 'bg-red-300',

  text_color_dark: 'text-sky-800',

  btn_bg_color: 'bg-sky-800',
  btn_bg_hover: "bg-sky-700",
};
