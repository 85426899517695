/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (
    item && typeof item === "object" && !Array.isArray(item) && item !== null
  );
}

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export function mergeDeep(target, source) {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return target;
}

export const sortArrByDate = (
  dataArr = [],
  dateField = "date",
  sortBy = "desc"
) => {
  // return empty array if there is no data found
  if (!dataArr || !dataArr.length) {
    return [];
  }

  if (sortBy === "asc") {
    return dataArr.sort(
      (a, b) => new Date(a[dateField]) - new Date(b[dateField])
    );
  }
  // sort by date desc
  return dataArr.sort(
    (a, b) => new Date(b[dateField]) - new Date(a[dateField])
  );
};

export const sortArrByString = (
  dataArr = [],
  fieldName = "",
  sortBy = "desc"
) => {
  if (sortBy == "asc") {
    return dataArr.sort((a, b) =>
      a[fieldName] &&
        b[fieldName] &&
        a[fieldName].toUpperCase() > b[fieldName].toUpperCase()
        ? 1
        : -1
    );
  } else {
    return dataArr.sort((a, b) =>
      a[fieldName] &&
        b[fieldName] &&
        a[fieldName].toUpperCase() > b[fieldName].toUpperCase()
        ? -1
        : 1
    );
  }
};


export const sortDataMain = (data, dataField = "sequence") => {
  console.log("data", data);
  let dataSort = data.sort((a, b) => a[dataField] - b[dataField]);
  // let dataSort = data.sort((a, b) => a.created - b.created);

  dataSort.forEach((value) => {
    value.sub_theme.sort((a, b) => a[dataField] - b[dataField]);

    value.sub_theme.forEach((row) => {
      /*  row.question.sort((a, b) => a[dataField] - b[dataField]); */
      row.question.sort((a, b) => {
        let numA = a.sequence.split(".").map(Number);
        let numB = b.sequence.split(".").map(Number);
        for (var i = 0; i < Math.max(numA.length, numB.length); i++) {
          var partA = numA[i] || 0; // Use 0 if the part doesn't exist
          var partB = numB[i] || 0;

          if (partA < partB) {
            return -1; // a should come before b
          }
          if (partA > partB) {
            return 1; // a should come after b
          }
        }
        return 0;
      });
    });
  });
  //console.log("dataSort", dataSort);
  return dataSort;
};

// Make first letter capital and rest small letters
export const capitalizeFirstLetter = (string) => {
  const str = string.trim();
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}