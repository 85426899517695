import React, { useState, useEffect } from "react";
import { BACKEND_DATA } from "../../data";

import { useNavigate, useParams } from "react-router-dom";

import ErrorPage from "../../components/common/ErrorPage";
import { LoadingSkeleton } from "../../components/common/LoadingSkeleton";
import useApiCallOnMount from "../../components/hoc/useApiCallOnMount";
import { getFeedbackById } from "../../service";
import Spinner from "../../components/common/Spinner";
import { getUser, clearUser } from "../../service/Auth";
import NewWizard from "../../components/NewWizard";

const NewFeedback = (props) => {
    const { id } = useParams();

    const user = getUser();

    const navigateTo = (path) => navigate(path);

    const navigate = useNavigate();

    const [data, setdata] = useState(null);
    const [isError, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    //  Use HOC 'useApiCallOnMount' to call api on mount
    //   const [isLoading, data = [], isError] = useApiCallOnMount(getFeedbackById(id));

    useEffect(() => {
        fetchFeedbackData();
    }, []);

    const fetchFeedbackData = async () => {
        try {
            setLoading(true);
            // Fetch history page data / api logic
            getFeedbackById(id).then((data) => {
                console.log("data ", data);
                if (!data.errorData) {
                    setdata(data);
                    setLoading(false);
                } else {
                    setError("Error while fetching data for selected feedback.");
                }
            });
        } catch (err) {
            console.log("Error ", err);
        }
    };

    useEffect(() => {
        if (user === "null") {
            clearUser();
            navigateTo("/login");
            return;
        }
    }, [user]);

    const goBackBtn = () => {
        return (
            <button
                class="btn btn-outline btn-primary m-5"
                onClick={() => navigate(-1)}
            >
                Back to History
            </button>
        );
    };

    const showFeedback = () => {
        return (
            <>
                <NewWizard selectedFormData={data} />
            </>
        );
    };

    if (isError) {
        return (
            <div>
                <ErrorPage />
                {goBackBtn()}
            </div>
        );
    }

    return <>{isLoading ? <Spinner /> : showFeedback()}</>;
};

export default NewFeedback;
