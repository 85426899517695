import React, { useState } from "react";

function SelectOption(props) {
  const { options = [], onChange, selectedOption, isDisable } = props;

  return (
    <select
      className="select w-full max-w-xs text-base"
      onChange={(e) => onChange(e.target.value)}
      disabled={isDisable}
    >
      <option disabled selected>
        Select Project
      </option>
      {options.map((option, index) => {
        return (
          <option
            key={`${option.title}-index`}
            value={option.title}
            selected={selectedOption === option.title}
          >
            {option.title}
          </option>
        );
      })}
    </select>
  );
}

export default SelectOption;
