const SMILEY_SIZE = "";
const SMILIES = [
  {
    rating: "1 but less than 2",
    name: "strongly Disagree",
    svg: (
      <svg
        style={{ color: "red" }}
        class="mr-1 h-7 w-7 text-primary dark:text-red-500"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        {" "}
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-2.715 5.933a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"></path>{" "}
      </svg>
    ),
  },
  {
    rating: "2 but less than 3",
    name: "Agree",
    svg: (
      <svg
        style={{ color: "#fbbf24" }}
        class="mr-1 h-7 w-7 text-primary dark:text-amber-400"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        {" "}
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-3 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />{" "}
      </svg>
    ),
  },
  {
    rating: "3 or more",
    name: "strongly Agree",
    svg: (
      <svg
        style={{ color: "#84cc16" }}
        class="mr-1 h-7 w-7 text-primary dark:text-green-500"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        {" "}
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zM4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />{" "}
      </svg>
    ),
  },
];

// empty-Star svg icon
const emptyStar = () => {
  return (
    <li>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16"
        class="mr-1 h-4 w-4 text-warning"
        style={{ color: "orange" }}
      >
        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
      </svg>
    </li>
  );
};
// filled-Star svg icon
const fullStar = () => {
  return (
    <li>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16"
        class="mr-1 h-4 w-4 text-warning"
        style={{ color: "orange" }}
      >
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
      </svg>
    </li>
  );
};

// filled-Star svg icon
const halfStar = () => {
  return (
    <li>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16"
        class="mr-1 h-4 w-4 text-warning"
        style={{ color: "orange" }}
      >
        <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"></path>
      </svg>
    </li>
  );
};

// empty-medal svg icon
const emptyMedal = () => {
  return (
    <li>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mr-1 h-4 w-4 dark:text-slate-300"
        fill="currentColor"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" />
      </svg>
    </li>
  );
};

// filled-medal svg icon
const filledMedal = () => {
  return (
    <li>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mr-1 h-5 w-5 text-primary dark:text-[#fed94b]"
        fill="currentColor"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" />
      </svg>
    </li>
  );
};

function isFloat(n) {
  return n % 1 !== 0;
}

const getSmiley = (score) => {
  if (score <= 2.49) {
    return SMILIES[0].svg;
  } else if (score >= 2.5 && score <= 3.25) {
    return SMILIES[1].svg;
  } else if (score > 3.25) {
    return SMILIES[2].svg;
  }

  return null;
};

const StarRating = (props) => {
  const { value } = props;
  let stars = [];
  let totalRating = 4;
  let starRating = value;

  let roundValue = parseFloat(value).toFixed();

  for (let i = 1; i <= totalRating; i++) {
    // let starRating = value >= i ? stars.push(fullStar()) : stars.push(emptyStar())
    if (starRating >= i) {
      stars.push(fullStar());
    } else {
      // When its float
      // Show if decimal value is <= (.5) show half star else for .6 >= fullStar
      const checkDecimal = starRating.split(".")[1];
      if (
        isFloat(starRating) &&
        checkDecimal > 5 &&
        Math.round(starRating) == i
      ) {
        stars.push(fullStar());
      } else if (isFloat(starRating) && Math.floor(starRating) == i - 1) {
        stars.push(halfStar());
      } else {
        stars.push(emptyStar());
      }
    }
  }

  return (
    <>
      <ul class="flex items-center justify-center">
        <li className="pb-2">{getSmiley(value)}</li>
      </ul>
      <ul class="flex justify-center">{stars}</ul>
    </>
  );
};
export default StarRating;
