// const Checkbox = ({ data = [], handleSelection, }) => {
//   console.log("answers ", data);
//   return (
//     <div className="custom-controls-stacked">
//       {data.map((answer, index) => {
//         return (
//           <label className="custom-control custom-checkbox">
//             <input
//               type="checkbox"
//               name={`answers-${data.id}`}
//               className="custom-control-input"
//               value={index}
//               onChange={handleSelection}
//             />
//             <span className="custom-control-indicator" />
//             <span className="custom-control-description">{data.title}</span>
//           </label>
//         );
//       })}
//     </div>
//   );
// };

import Checkbox from "../common/Checkbox";

const Group = (props) => {
  let { data = [], defaultSelected = {}, handleSelection, handleGroupSelection, groupsList } = props;

  const onChange = (event) => {
    const val = event.target.checked;
    const name = event.target.name;
    // handleSelection({ ...defaultSelected, [name]: val });
    handleGroupSelection(name, { ...defaultSelected, [name]: val });
  };

  // Get selected keys of checkbox
  const selectedOption = Object.keys(defaultSelected).filter(
    (k) => defaultSelected[k] === true
  );

  return (
    <Checkbox
      // options={data && data["groups"]}
      options={groupsList}
      selectedOption={selectedOption}
      onChange={onChange}
      {...props}
    />
  );
};
export default Group;
