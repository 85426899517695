import React, { useState, useEffect } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

const Multiselect = (props) => {
  const { options, selectedCategory, handleNewTag, removeNewTag, isDisable } =
    props;

  const handleSelect = (data, actionMeta, e) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "create-option"
    ) {
      // Add selected value to multiselect state: input
      handleNewTag(actionMeta.option);
    } else {
      // Add selected value to multiselect state: input
      removeNewTag(actionMeta.removedValue);
    }
  };

  return (
    <div className="app">
      <div className="dropdown-container text-base text-left">
        <CreatableSelect
          isMulti
          isDisabled={isDisable}
          placeholder="Select Categories..."
          onChange={handleSelect}
          options={options}
          value={selectedCategory}
          hideSelectedOptions={false}
          isClearable={false}
        />
      </div>
    </div>
  );
};

export default Multiselect;
