import { useEffect } from "react";
import SelectOption from "../common/SelectOption";
import TopicSelect from "./TopicSelect";

const Topic = (props) => {
  const { data, selectedOption } = props;

  useEffect(() => {
    // set first value on load if not selected any option
    if (!selectedOption) {
      // props.handleSelection(data[0] && data[0].title);
    }
  }, []);

  const handleOnChange = (val) => {
    props.handleSelection(val.value);
  };

  return (
    <div>
      {/* <SelectOption options={props.data} onChange={handleOnChange} {...props} /> */}
      <TopicSelect options={props.data} onChange={handleOnChange} {...props} />
    </div>
  );
};
export default Topic;
