import React from "react";
import PropTypes from "prop-types";

const getBgColor = (value) => {
  return value > 0 && value < 5 ? "bg-red-700" : "bg-green-700";
};

const infoDot = (value) => {
  return <div className={`w-3 h-3 rounded-full ${getBgColor(value)}`}></div>;
};

const ChartTooltip = ({ data = [], onCardClick, onCardMergeClick }) => {
  const getCard = (payload, i) => (
    <div>
      <div className="flex flex-col items-start bg-slate-100 p-2 border-2">
        <p className="font-semibold">{payload.title}</p>
        <div className="flex justify-evenly items-center">
          {infoDot(payload.power)}
          <p className="font-medium text-sm pl-2">Power: {payload.power} </p>
        </div>
        <div className="flex justify-center items-center">
          {infoDot(payload.interest)}
          <p className="font-medium text-sm pl-2">
            Interest: {payload.interest}{" "}
          </p>
        </div>
        <div className="flex justify-center items-center">
          {infoDot(payload.alignment)}
          <p className="font-medium text-sm pl-2">
            Alignment: {payload.alignment}{" "}
          </p>
        </div>
        <div className="flex justify-center items-center">
          {infoDot(payload.interest)}
          <p className="font-medium text-sm pl-2">
            Influencability: {payload.influencability}{" "}
          </p>
        </div>
        <div>
          {/* <button className="btn btn-link text-sm items-left pt-1" onClick={() => onCardClick('groups', payload)}>View Group Document</button>
          <button className="btn btn-link text-sm items-left pt-0" onClick={() => onCardClick('actions', payload)}>View Actions</button> */}
          <button
            className="btn btn-link text-sm items-left pt-0"
            onClick={() => onCardMergeClick(payload)}
          >
            View Proposed Actions
          </button>
        </div>
      </div>
    </div>
  );

  const listView = (items = []) =>
    items.map((item, i) => (
      <div key={item.id} style={{ cursor: "pointer" }}>
        {getCard(item, i)}
      </div>
    ));

  return data.length ? (
    <div className="scrollbar" id="tooltip-custom-scroll">
      <div className="force-overflow">{listView(data)}</div>
    </div>
  ) : null;
};

ChartTooltip.propTypes = {
  data: PropTypes.array.isRequired,
  onCardClick: PropTypes.func,
};

export default ChartTooltip;
