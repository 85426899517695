import { NavLink, useNavigate } from "react-router-dom";
import {
  ABOUT_MENU,
  ABOUT_ROUTE,
  FEEDBACK_MENU,
  FEEDBACK_MENU_NEW,
  FEEDBACK_ROUTE,
  FEEDBACK_ROUTE_NEW,
  LOGIN_MENU,
  LOGIN_ROUTE,
  LOGOUT_MENU,
  QUESTIONS_MENU,
  QUESTIONS_ROUTE,
  QUESTION_MENU,
  QUESTIONS,
  USER_REGISTRATION_MENU,
  USER_REGISTRATION_ROUTE,
} from "../../constants/Routes";
import ProfileMenu from "../common/ProfileMenu";
import { themeDefault } from "../../constants/themeSettings";
import { clearUser, getUser } from "../../service/Auth";
import { EOSE_HOME_APP_URL } from "../../constants/api";

const activeClassName = `${themeDefault.text_color_dark}`;

const nav_item_text_style = `flex px-3 py-1 ml-2 self-center text-base text-gray-800 rounded-md focus:outline-none hover:bg-sky-100 hover:${activeClassName}`;

const MENU_ITEM = [
  {
    title: `${FEEDBACK_MENU.title}`,
    id: `${FEEDBACK_MENU.id}`,
    path: `/${FEEDBACK_ROUTE}`,
  },

  /* {
    title: `${ABOUT_MENU.title}`,
    id: `${ABOUT_MENU.id}`,
    path: `${ABOUT_ROUTE}`,
  }, */
];

const ADMIN_ITEM = [
  // {
  //   title: `${QUESTIONS_MENU.title}`,
  //   id: `${QUESTIONS_MENU.id}`,
  //   path: `admin/${QUESTIONS_ROUTE}`,
  // },
  {
    title: `${FEEDBACK_MENU.title}`,
    id: `${FEEDBACK_MENU.id}`,
    path: `/${FEEDBACK_ROUTE}`,
  },
  // {
  //   title: `${USER_REGISTRATION_MENU.title}`,
  //   id: `${USER_REGISTRATION_MENU.id}`,
  //   path: `/${USER_REGISTRATION_ROUTE}`,
  // },
];

function NavBar() {
  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);

  //const user = getUser();

  const user = JSON.parse(getUser());
  //const check = JSON.parse(getUser());
  console.log("user", user);

  function onLogout() {
    clearUser();
    navigateTo("/");
  }

  return (
    <div className="flex justify-center space-x-4 items-center">
      <nav className="flex justify-center space-x-4">
        {(() => {
          if (user && user.role === "admin") {
            return ADMIN_ITEM.map((obj, index) => {
              return (
                <NavLink
                  key={`${obj.title}-index`}
                  to={obj.path}
                  className={({ isActive }) =>
                    `${isActive ? activeClassName : ""} ${nav_item_text_style}`
                  }
                >
                  {obj.title}
                </NavLink>
              );
            });
          } else if (user && (user.role === "tester" || user.role === null)) {
            return MENU_ITEM.map((obj, index) => {
              return (
                <NavLink
                  key={`${obj.title}-index`}
                  to={obj.path}
                  className={({ isActive }) =>
                    `${isActive ? activeClassName : ""} ${nav_item_text_style}`
                  }
                >
                  {obj.title}
                </NavLink>
              );
            });
          } else {
            return null;
          }
        })()}
        <a
          className={`${nav_item_text_style} tooltip tooltip-bottom`}
          data-tip="Click to open Home Application"
          href={EOSE_HOME_APP_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`Home Page`}
        </a>

        {!user && (
          <NavLink
            to={`${LOGIN_ROUTE}`}
            className={({ isActive }) =>
              `${isActive ? activeClassName : ""} ${nav_item_text_style}`
            }
          >
            {`${LOGIN_MENU.title}`}
          </NavLink>
        )}
        {user && <ProfileMenu getUser={getUser} onLogout={onLogout} />}
      </nav>
    </div>
  );
}
export default NavBar;
