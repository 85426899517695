const ProfilePage = ({ getUser }) => {
  let name = JSON.parse(getUser());
  let { firstName = "", lastName = "" } = name || {};

  let initials =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div
      class='w-full justify-center items-center px-6'
      style={{ marginTop: "30px" }}
    >
      <div class='p-8 rounded-lg m-8'>
        <h1 className='text-3xl font-bold text-sky-800'>Profile Details</h1>
        <div className='divider'></div>
        <div className='card h-full bg-base-100  mb-5 flex justify-center items-center text-lg font-semibold text-slate-500'>
          <p className='w-32 h-32 mb-3 text-4xl text-gray-800 mt-4 pt-12 rounded-full shadow-lg bg-sky-300'>
            {initials}
          </p>
          <span class='text-2xl font-bold text-slate-600 pb-2'>
            {capitalizeFirstLetter(firstName) +
              " " +
              capitalizeFirstLetter(lastName)}
          </span>
          <span class='text-xl font-medium text-slate-500 pb-2'>
            Emaild Id: {name.email}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
