import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getUser, getUserRole } from "../service/Auth";
import { useState } from "react";

const ProtectedAdminRoute = (props) => {
  const { children } = props;

  const location = useLocation();

  const roles = getUserRole();

  if (!roles) {
    console.log("pathname", location.pathname);
    return (
      <Navigate to={"/pagenotfound"} state={{ path: location.pathname }} />
    );
  }

  return (
    <>
      {children}
      <Outlet />
    </>
  );
};
export default ProtectedAdminRoute;
