import { Link } from "react-router-dom";

export default function FormExtra({ showRememberMe = false }) {
  return (
    <div className="flex items-center justify-between ">
      <div className="flex items-center">
        {
          showRememberMe &&
          <>
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 border-gray-300 rounded"
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              Remember me
            </label>
          </>
        }
      </div>

      <div className="text-sm">
        <Link
          to="/forgotpassword"
          className="font-medium text-blue-600 hover:text-blue-500"
        >
          Forgot your password?
        </Link>
      </div>
    </div>
  );
}
