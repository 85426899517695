import { themeDefault } from "../../constants/themeSettings";

const btn_bgcolor_over = "bg-cyan-300";

export default function FormAction({
    handleSubmit,
    type = 'Button',
    action = 'submit',
    text
}) {
    return (
        <>
            {
                type === 'Button' ?
                    <button
                        className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-bold rounded-md text-white ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-10`}
                        type={action}
                        onSubmit={handleSubmit}
                    >

                        {text}
                    </button>
                    :
                    <></>
            }
        </>
    )
}