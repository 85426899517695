const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "login",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
];

const signupFields = [
  {
    labelText: "FirstName",
    labelFor: "firstname",
    id: "firstName",
    name: "firstname",
    type: "text",
    autoComplete: "firstname",
    isRequired: true,
    placeholder: "First Name",
  },
  {
    labelText: "LastName",
    labelFor: "lastname",
    id: "lastName",
    name: "lastname",
    type: "text",
    autoComplete: "lastname",
    isRequired: true,
    placeholder: "Last Name",
  },
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
    enableOnBlur: true,
    isMatchField: "confirmPassword",
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirm-password",
    id: "confirmPassword",
    name: "confirm-password",
    type: "password",
    autoComplete: "confirm-password",
    isRequired: true,
    placeholder: "Confirm Password",
    isMatchField: "password",
  },
];

const forgotPasswordFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "forgotpassword",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
];

export { loginFields, signupFields, forgotPasswordFields };
