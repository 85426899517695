import { useState } from "react";
import { PRIVACY_POLICY_URL } from "../../constants/api";

export const PolicyCheck = ({ isChecked, btnText = "", handleOnPloicyCheckChange }) => {
    return (
        <div className="flex items-center">
            <input
                id="policy-privacy"
                name="policy-privacy"
                type="checkbox"
                checked={isChecked}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                onChange={handleOnPloicyCheckChange}
            />
            <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-600"
            >
                {`${btnText} `}
                <a
                    className="text-sm text-blue-600"
                    href={PRIVACY_POLICY_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    Privacy Policy.
                </a>
            </label>
        </div>
    )
}