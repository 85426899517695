export const LoadingSkeleton = () => {
  // className="max-w-l rounded overflow-hidden shadow-lg glass"
  return (
    <div>
      <div className="border border-blue-300 shadow rounded-md p-4 max-w-l w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-700 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                <div className="h-2 bg-slate-700 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-700 rounded"></div>
            </div>

            <div className="flex justify-between">
              <button disabled className="btn btn-outline">
                ....
              </button>
              <button disabled className="btn btn-outline">
                ....
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
