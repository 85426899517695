import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  LOGIN_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  ABOUT_ROUTE,
} from "../constants/Routes";
import { getUser, getUserRole } from "../service/Auth";
export const RequireAuth = (props) => {
  const { children } = props;
  const location = useLocation();
  const user = getUser();
  const roles = getUserRole();

  if (roles) {
    /*  if (location.pathname !== "/") {
      return <Navigate to="/pagenotfound" />;
    }
    return (
      <Navigate
        to={`admin/${ADMIN_DASHBOARD_ROUTE}`}
        state={{ path: location.pathname }}
      />
    ); */
  }
  if (!user) {
    return (
      <Navigate to={`${LOGIN_ROUTE}`} state={{ path: location.pathname }} />
    );
  }
  return (
    <>
      {children}
      <Outlet />
    </>
  );
};
