import { useNavigate } from "react-router-dom";
const ProfileMenu = ({ onLogout, getUser }) => {
  const navigate = useNavigate();
  let name = JSON.parse(getUser());
  let { firstName = "", lastName = "" } = name || {};

  let initials =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className='dropdown dropdown-end'>
        <label
          tabIndex={0}
          className='btn btn-ghost btn-circle avatar bg-sky-300 hover:bg-sky-200 hover:text-blue-700'
        >
          <h1 className='text-base'>{initials}</h1>
        </label>
        <ul
          tabIndex={0}
          className='space-y-[5px] mt-3 p-2 shadow menu menu-normal dropdown-content bg-base-100 rounded-md w-60 z-[2]'
        >
          <li>
            <a
              className='justify-between focus:outline-none hover:bg-blue-100 hover:text-blue-700'
              onClick={() => navigate("/profile")}
            >
              {capitalizeFirstLetter(firstName) +
                " " +
                capitalizeFirstLetter(lastName)}
            </a>
          </li>

          <li>
            <a
              className='justify-between focus:outline-none hover:bg-blue-100 hover:text-blue-700'
              onClick={() => onLogout()}
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default ProfileMenu;
