import React, { useState, useEffect } from "react";
import { BACKEND_DATA } from "../../data";
import {
  getTopics,
  getGroups,
  getTheme,
  updateQuestionApi,
  updateSubThemeApi,
  addNewQuestion,
  addNewQuestionRelation,
} from "../../service";
import { LoadingSkeleton } from "../common/LoadingSkeleton";

import useApiCallOnMount from "../hoc/useApiCallOnMount";

import ErrorPage from "../common/ErrorPage";

import { useNavigate } from "react-router-dom";
import { getUser, clearUser } from "../../service/Auth";
import NewFeedbackForm from "./NewFeedbackForm";
import {
  ALERT_TYPE_ERROR,
  ALERT_TYPE_SUCCESS,
  QUESTION_TABLE,
  TABLE_QUESTION,
  TABLE_SUB_THEME,
  TABLE_THEME,
} from "../../constants";
import ToastMessage from "../common/ToastMessage";
import Spinner from "../common/Spinner";
import { sortDataMain } from "../../components/utility";
import AppFooter from "../common/AppFooter";

const NewWizard = (props) => {
  const { selectedFormData } = props;
  const user = getUser();
  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);

  //  Use HOC 'useApiCallOnMount' to call api on mount
  // const [isLoading, data = [], isError] = useApiCallOnMount(getTopics);
  const [isLoading, setLoader] = useState(false);
  const [isError, setError] = useState("");
  const [themeData, setThemeData] = useState([]);

  const [isProcessing, setisProcessing] = useState(false);
  const [alert, setAlert] = useState(null);

  const [formData, setFormData] = useState(selectedFormData || {});

  const [activeStepIndex, setselectedStepIndex] = useState(0); //This is object of selected step
  const [activeStepData, setselectedStepData] = useState({}); //This is object of selected step

  const [newQuestionText, setNewQuestionText] = useState("");
  const [showNewQuestionBlockForId, setshowNewQuestionBlockForId] =
    useState(null);

  const [isEditModeEnable, setisEditModeEnable] = useState(false);

  useEffect(() => {
    setLoader(true);
    fetchFormInitialData();
  }, []);

  const fetchFormInitialData = () => {
    getTheme()
      .then((response) => {
        if (!response.errorData) {
          setThemeData(sortDataMain(response));
          setselectedStepData(response[activeStepIndex]);
          setLoader(false);
          setisProcessing(false);
        } else throw new Error("Something went wrong");
      })
      .catch(() => {
        setLoader(false);
        setisProcessing(false);
        setError(
          "Error occured while fetching Theme api data ! Please contact to admin."
        );
      });
  };

  const handleAlert = (alertValue) => {
    setisProcessing(false);
    setAlert(alertValue);
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const handleCloseToast = () => {
    setAlert(null);
  };

  const handleOnEnterSave = (value, objectId, tableName, columnName) => {
    // Based on table call update api
    setisProcessing(true);
    if (tableName === TABLE_SUB_THEME) {
      updateSubThemeApi(objectId, value, columnName)
        .then((response) => {
          if (!response.errorData) {
            console.log("updateSubThemeApi sucess");
            setisProcessing(false);
            handleAlert({
              message: "Data Updated successfully!",
              type: ALERT_TYPE_SUCCESS,
            });
          } else throw new Error("Something went wrong");
        })
        .catch(() => {
          setisProcessing(false);
          handleAlert({
            message: "Failed to update data! Please contact to admin.",
            type: ALERT_TYPE_ERROR,
          });
        });
    } else if (tableName === TABLE_QUESTION) {
      updateQuestionApi(objectId, value, columnName)
        .then((response) => {
          if (!response.errorData) {
            console.log("updateQuestionApi sucess");
            setisProcessing(false);
            handleAlert({
              message: "Data Updated successfully!",
              type: ALERT_TYPE_SUCCESS,
            });
          } else throw new Error("Something went wrong");
        })
        .catch(() => {
          setisProcessing(false);
          handleAlert({
            message: "Failed to update data! Please contact to admin.",
            type: ALERT_TYPE_ERROR,
          });
        });
    }
  };

  const handleOnTextChange = (
    id,
    tableName,
    columnName,
    value,
    subThemeindex,
    queIndex
  ) => {
    const newselectedStepData = { ...activeStepData };

    // Based on clicked text update selectedStep object state
    if (tableName === TABLE_THEME) {
      newselectedStepData[columnName] = value;
    } else if (tableName === TABLE_SUB_THEME) {
      newselectedStepData.sub_theme[subThemeindex][columnName] = value;
    } else if (tableName === TABLE_QUESTION) {
      newselectedStepData.sub_theme[subThemeindex].question[queIndex][
        columnName
      ] = value;
    }

    handleSelectedStepData(newselectedStepData);
  };

  const handleNewQuestionTextChange = (text) => {
    setNewQuestionText(text);
  };
  const handleDiscardNewQuestion = () => {
    setshowNewQuestionBlockForId(null);
    setNewQuestionText("");
  };
  // Enables new question input block
  const handleShowNewQuestionClick = (id) => {
    setshowNewQuestionBlockForId(id);
  };

  const handleOnEditModeClick = () => {
    setisEditModeEnable(!isEditModeEnable);
  };

  const createQuestionSequence = (subtheme) => {
    let questionArrLength = subtheme.question.length;
    let subthemeSequenceLastValue = subtheme.question[questionArrLength - 1];
    let lastValueofQuestion = subthemeSequenceLastValue.sequence.split(".");
    let lastValueIncrement = parseInt(lastValueofQuestion[1]) + 1;
    //console.log("lastValueIncrement", lastValueIncrement);

    let questionSequience = `${lastValueofQuestion[0]}.${lastValueIncrement}`;
    //console.log("questionSequience", questionSequience);
    return questionSequience;
  };

  const handleNewQuestionSave = (text, subTheme) => {
    try {
      let questionSequence = createQuestionSequence(subTheme);
      setisProcessing(true);
      if (!text) {
        setisProcessing(false);
        handleAlert({
          message: "Please Add some Text to new question.",
          type: ALERT_TYPE_ERROR,
        });
        return;
      }

      addNewQuestion(text, questionSequence).then((data) => {
        // Add relation to newly created question with related sub-theme
        addNewQuestionRelation(subTheme.objectId, data.objectId).then(
          (data) => {
            fetchFormInitialData();
            setNewQuestionText("");
            handleShowNewQuestionClick();
            handleAlert({
              message: "New question added successfully!",
              type: ALERT_TYPE_SUCCESS,
            });
          }
        );
      });
    } catch (error) {
      setisProcessing(false);
      handleAlert({
        message:
          "Error occured while adding Questions data ! Please contact to admin.",
        type: ALERT_TYPE_ERROR,
      });
    }
  };

  const handleSelectedStepData = (data) => {
    setselectedStepData(data);
  };

  // Handle step click : set index and data for index
  const handleOnStepClick = (stepIndex, stepData) => {
    if (themeData && stepIndex < themeData.length) {
      const stepDataObj = themeData && themeData[stepIndex];
      setselectedStepData(stepDataObj);
      setselectedStepIndex(stepIndex);
    }
  };

  useEffect(() => {
    if (user === "null") {
      clearUser();
      navigateTo("/login");
      return;
    }
  }, [user]);

  if (isError) {
    return <ErrorPage />;
  }

  const singleFormView = () => {
    if (!themeData || !themeData.length) return null;

    return (
      <NewFeedbackForm
        data={themeData}
        defaultFormData={formData}
        activeStepIndex={activeStepIndex}
        selectedStepData={activeStepData}
        handleOnStepClick={handleOnStepClick}
        handleOnTextChange={handleOnTextChange}
        handleOnEnterSave={handleOnEnterSave}
        newQuestionText={newQuestionText}
        showNewQuestionBlockForId={showNewQuestionBlockForId}
        handleShowNewQuestionClick={handleShowNewQuestionClick}
        handleNewQuestionTextChange={handleNewQuestionTextChange}
        handleNewQuestionSave={handleNewQuestionSave}
        handleDiscardNewQuestion={handleDiscardNewQuestion}
        isEditModeEnable={isEditModeEnable}
        handleOnEditModeClick={handleOnEditModeClick}
      />
    );
  };

  const showLoader = () => {
    if (isProcessing) {
      return (
        <div className="flex h-screen absolute -top-10 left-1/2 z-50">
          <Spinner />
        </div>
      );
    }
    return <LoadingSkeleton />;
  };

  return (
    <div>
      <div className="w-full py-10 px-4 sm:px-6 lg:px-8 ">
        <div className="mt-5 max-w-l flex justify-center rounded overflow-visible shadow-lg border">
          {isLoading ? <LoadingSkeleton /> : singleFormView()}

          {isProcessing && (
            <div className="flex h-screen fixed -top-10 left-1/2 z-50">
              <Spinner />
            </div>
          )}
          {alert && <ToastMessage alert={alert} closeToast={handleCloseToast} />}
        </div>
      </div>
      {!isLoading && <AppFooter />}
    </div>
  );

  // This is card view form
  return <div className="w-11/12"></div>;
};

export default NewWizard;
