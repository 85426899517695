import { useState, useEffect } from "react";
import SpiderChart from "../../components/Charts/SpiderChart";
import { ButtonCommon } from "../../components/common/ButtonCommon";
import { ButtonOutline } from "../../components/common/ButtonOutline";
import {
  PILLAR_HASH_COLORS,
  PILLAR_TEXT_COLORS,
  STICK_TITLE,
  PILLAR_BG_COLORS,
} from "../../constants";
import StarRating from "../../components/common/StarRating";
import { themeDefault } from "../../constants/themeSettings";
import { capitalizeFirstLetter } from "../../components/utility";

const DATA_KEY_NAME = "title";
const DATA_KEY_VALUE = "value";
const DOMAIN_RANGE = [0, 4];

// Number of decimals digits show in avg
const DECI_DIGITS = 1;

const THEME_TAG = "theme";
const SUBTHEME_TAG = "subTheme";

const NAV_BAR_CHART = ["Overall", "P1", "P2", "P3", "P4"];

const OVERALL_POINT = {
  [DATA_KEY_NAME]: "Overall",
  [DATA_KEY_VALUE]: "Overall",
};

const TOOLTIP_TEXT =
  "You can Access Relevant Learning Resources to help you improve by clicking the button above.";

const NODATA_MSG_OVERALL =
  "You need to complete at least three pillars to see overall results for the self-assessment.";

const NODATA_MSG_INDIVIDUAL =
  "You need to complete at least three sub-pillars to see pillar results for the self-assessment.";

const ROADMAP_URL =
  "http://pointsapp.novagov.com/system/documents/docs/000/000/072/original/Roadmap_P1_H2_Risk_Management_consolidated_-_Copy.pdf?1588599957";

const roundOffFloatValue = (value) => {
  return parseFloat(value).toFixed();
};

const getGraphFormattedData = (feedbackData, isOverAll) => {
  if (!feedbackData) return [];
  const radarData = {};

  let categoryAxisPoints = [];

  if (!isOverAll) {
    // Calculate avg per sub_pillar for selected Pillar
    const themeData = feedbackData.sub_theme;
    themeData.map((subtheme) => {
      //   calculate avg
      let totalQuesPerSubtheme = 0;
      let sumOfRating = 0;

      // filter question having values only
      const questionsArr =
        subtheme.question && subtheme.question.filter((q) => q?.value);
      totalQuesPerSubtheme += Object.keys(questionsArr).length;

      questionsArr.map((que) => {
        sumOfRating += parseInt(que.value);
      });

      const avgRating = (sumOfRating / totalQuesPerSubtheme).toFixed(
        DECI_DIGITS
      );

      if (!isNaN(avgRating)) {
        const dataPoint = {
          ...subtheme,
          title: `Sub-Pillar ${subtheme.sequence}: ${capitalizeFirstLetter(
            subtheme.title
          )}`,
          [DATA_KEY_VALUE]: avgRating,
        };
        categoryAxisPoints.push(dataPoint);
      }
    });
  } else {
    // Calculate avg per pillar
    feedbackData.map((theme) => {
      let totalQuesPerSubtheme = 0;
      let sumOfRating = 0;

      theme.sub_theme.map((subtheme) => {
        const questionsArr =
          subtheme.question && subtheme.question.filter((q) => q?.value);
        totalQuesPerSubtheme += Object.keys(questionsArr).length;

        questionsArr.map((que) => {
          sumOfRating += parseInt(que.value);
        });
      });

      const avgRating = (sumOfRating / totalQuesPerSubtheme).toFixed(
        DECI_DIGITS
      );

      if (!isNaN(avgRating)) {
        const dataPoint = {
          ...theme,
          title: `PILLAR ${theme.sequence}: ${capitalizeFirstLetter(
            theme.title
          )}`,
          [DATA_KEY_VALUE]: avgRating,
        };
        categoryAxisPoints.push(dataPoint);
      }
    });
  }

  return categoryAxisPoints;
};

const RadarAnalytics = (props) => {
  const { data, fetchObjectByTitle } = props;
  const [activeTag, setactiveTag] = useState(THEME_TAG);
  const [selectedPoint, setselectedPoint] = useState(OVERALL_POINT);

  const selectedTabKey = selectedPoint[DATA_KEY_NAME];
  const isOverAll = selectedTabKey === OVERALL_POINT[DATA_KEY_NAME];

  const filterData = (feedback, selectedTabKey) => {
    const filteredArray = feedback.filter(
      (obj) => obj.sequence === selectedTabKey
    );
    return filteredArray[0];
  };

  const selectedTabData = isOverAll
    ? data.feedback
    : filterData(data.feedback, selectedTabKey);

  const graphData = getGraphFormattedData(selectedTabData, isOverAll);

  // Handler
  const handleDataPointClick = (item) => {
    setselectedPoint(item);
  };

  function findThemeWithSubThemes(array) {
    for (const theme of array.feedback) {
      if (theme.sub_theme && theme.sub_theme.length >= 3) {
        return theme;
      }
    }
    return null;
  }

  useEffect(() => {
    let subThemetoSet = findThemeWithSubThemes(data);
    if (data.feedback.length >= 3 || subThemetoSet == null) {
      setselectedPoint(OVERALL_POINT);
    } else {
      setselectedPoint({
        title: subThemetoSet.sequence,
        index: subThemetoSet.sequence,
      });
    }
  }, []);

  const isActiveBtn = (value) => {
    return value === selectedPoint[DATA_KEY_NAME];
  };

  const chartDetailHeader = (dataPoints1 = []) => {
    const dataPoints = [];

    for (const subtheme of data.feedback) {
      dataPoints.push({ [DATA_KEY_NAME]: subtheme.sequence });
    }

    return (
      <div id="chartDetailsHeader" className="py-3 bg-slate-100">
        <p className="font-semibold text-sky-400 pb-2 px-4">
          Visualise your performance per Pillar
        </p>

        <ButtonOutline
          title={OVERALL_POINT[DATA_KEY_NAME]}
          style={""}
          isActive={isActiveBtn(OVERALL_POINT[DATA_KEY_NAME])}
          onClick={() => handleDataPointClick(OVERALL_POINT)}
        />

        {dataPoints.map((item, index) => {
          return (
            <span>
              <ButtonOutline
                title={`Pillar ${item.title}`}
                // title={item[DATA_KEY_NAME].match(/\b\w/g)
                //   .join("")
                //   .toUpperCase()}
                style={"ml-1"}
                isActive={isActiveBtn(item[DATA_KEY_NAME])}
                onClick={() =>
                  handleDataPointClick({ ...item, ...{ index: item.title } })
                }
              />
            </span>
          );
        })}
      </div>
    );
  };

  const handleDownload = (btnType, value, selectedObject) => {
    fetchObjectByTitle(btnType, value, selectedObject);
  };

  const showDownloadRoadmap = (btnType, value, selectedObject) => {
    return (
      <div className="pt-1">
        <button
          class="text-sm font-medium text-blue-600 px-1 hover:font-semibold hover:bg-blue-50 hover:border-blue-400 border-transparents border-2 rounded-md bg-blue-50"
          onClick={() => handleDownload(btnType, value, selectedObject)}
        >
          Access Relevant Learning Resources
        </button>
      </div>
    );
  };

  // const roundOffFloatValue = (value) => {
  //   return parseFloat(value).toFixed();
  // };

  // return selectedTab data related to graph header

  const getSelectedTabData = () => {
    const selectedTabKey = selectedPoint[DATA_KEY_NAME];
    const isOverAll = selectedTabKey === OVERALL_POINT[DATA_KEY_NAME];
    //console.log("selectedTabKey", selectedTabKey);

    const selectedTabData = isOverAll
      ? OVERALL_POINT
      : filterData(data.feedback, selectedTabKey);

    return selectedTabData;
  };

  const getSpiderChartColor = () => {
    const pillarIndex = selectedPoint.index && selectedPoint.index - 1;

    // -1 as pillar index start from 1 to 4
    return PILLAR_BG_COLORS[pillarIndex] || "#1e40af";
  };

  const showGraphDetailBlock = (dataPoints = []) => {
    // To show 'Pillar No' as prefix before pillar title
    const titlePrefix = selectedPoint.index
      ? `Pillar ${selectedPoint.index}: `
      : "";

    // for overall table shows themes and for each theme its related sub-themes
    const downloadResourceFor =
      selectedPoint[DATA_KEY_NAME] === OVERALL_POINT[DATA_KEY_NAME]
        ? "theme"
        : "sub-theme";

    const selectedTabData = getSelectedTabData();

    return (
      <div className="p-1">
        {chartDetailHeader(dataPoints)}
        <hr />
        <div className="text-center font-semibold py-3 pb-4">
          <div className="text-sky-600 pb-1">{`${titlePrefix} ${selectedTabData[DATA_KEY_NAME]}`}</div>
          {selectedPoint[DATA_KEY_NAME] !== OVERALL_POINT[DATA_KEY_NAME] &&
            showDownloadRoadmap(
              "theme",
              selectedTabData[DATA_KEY_NAME],
              selectedTabData
            )}
        </div>

        <div id="chartDetails" className="pl-1">
          {dataPoints.map((point) => {
            //console.log("Points", point);
            return (
              <>
                <hr />
                <p className="text-start items-center px-0.5 py-3 font-medium text-sm text-slate-600 grid grid-cols-6 gap-2 hover:bg-slate-100">
                  <span className="col-span-4">
                    {/* {selectedPoint[DATA_KEY_NAME] === OVERALL_POINT[DATA_KEY_NAME] ? "PILLAR " : "Sub-Pillar "}
                    {`${point.sequence}: ${capitalizeFirstLetter(
                      point[DATA_KEY_NAME]
                    )}`}{" "} */}
                    {point[DATA_KEY_NAME]}
                  </span>
                  <span className="col-span-2">
                    <StarRating value={point[DATA_KEY_VALUE]} />
                  </span>

                  <div className="col-span-4">
                    {showDownloadRoadmap(
                      downloadResourceFor,
                      point.title,
                      point
                    )}
                  </div>
                  <div className="col-span-2 text-center">
                    {` ${point[DATA_KEY_VALUE]} out of 4`}
                  </div>
                </p>
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const showNoDataBlock = (msgText) => {
    return (
      <div>
        <div className="flex justify-center">
          <div class="flex justify-center bg-white shadow rounded-md w-fit">
            <div class={`rounded px-4 py-2 text-center bg-yellow-300`}>
              <svg
                class="h-8 w-8 text-yellow-500"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M12 9v2m0 4v.01" />{" "}
                <path d="M5.07 19H19a2 2 0 0 0 1.75 -2.75L13.75 4a2 2 0 0 0 -3.5 0L3.25 16.25a2 2 0 0 0 1.75 2.75" />
              </svg>
            </div>
          </div>
        </div>
        <p className="pt-5 font-bold text-base text-slate-400">{msgText}</p>
      </div>
    );
  };

  const showChartBlock = () => {
    const totalGraphPoints = (graphData && graphData.length) || 0;

    if (totalGraphPoints < 3) {
      const isOverAll = selectedTabKey === OVERALL_POINT[DATA_KEY_NAME];

      const msgText = isOverAll ? NODATA_MSG_OVERALL : NODATA_MSG_INDIVIDUAL;
      return <div className="p-8 text-lg ">{showNoDataBlock(msgText)}</div>;
    }

    return (
      <SpiderChart
        data={graphData}
        toolTipText={TOOLTIP_TEXT}
        dataKey={DATA_KEY_NAME}
        dataKeyVal={DATA_KEY_VALUE}
        domainRange={DOMAIN_RANGE}
        radarName={"Score"}
        color={getSpiderChartColor()}
      />
    );
  };

  return (
    <div className="flex flex-row">
      <div className="borderw border-red-100 w-8/12">{showChartBlock()}</div>
      <div className="border border-blue-100 w-full">
        {showGraphDetailBlock(graphData)}
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-8 gap-1 h-full">
      <div className="bg-red-200 col-span-5">
        <SpiderChart
          data={graphData}
          dataKey={DATA_KEY_NAME}
          dataKeyVal={DATA_KEY_VALUE}
          domainRange={DOMAIN_RANGE}
        />
      </div>
      <div className="bg-red-100 col-span-3">
        {showGraphDetailBlock(graphData)}
      </div>
    </div>
  );
};

export default RadarAnalytics;
