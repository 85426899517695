import { useState, useEffect } from "react";
import moment from "moment";
import {
  BTN_NEWFEEDBACK_TITLE,
  EDIT_ACTION,
  SUBMIT_STATUS,
  UPDATE_STATUS,
  VIEW_ACTION,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { themeDefault } from "../../constants/themeSettings";
import { WIZARD_ROUTE } from "../../constants/Routes";

const btn_bg_color_hover = `${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover}`;

const Card = (props) => {
  const {
    data,
    actionType,
    onRowClick,
    onSearchChange,
    searchText,
    tableFilter,
    sortColumn,
    sortOrder,
    handleSetSortOrder,
    handleGraphClick,
    handleDeleteFeedbackClick,
  } = props;

  //const [readMore, setReadMore] = useState(false);
  const [expandableData, setExpandableData] = useState(data);

  const expandResult = (index) => {
    const result = expandableData.slice(0);
    result[index]["expandable"] = result[index]["expandable"] ? false : true;
    setExpandableData(result);
  };

  //const NO_OF_ARRAY_ELE_SHOW = 3;
  //const NO_OF_ARRAY_ELE_SHOW_SUB_THEME = 2;

  useEffect(() => {
    if (data) {
      const expand = [...data];
      const response = expand.map((row) => {
        const feedback = (row.feedback || []).map((item) => {
          const sub = (item.sub_theme || []).filter((subTheme) => {
            const index = (subTheme.question || []).filter((queValue) => {
              return !!queValue?.value;
            });
            if (index.length > 0) {
              return true;
            } else {
              return false;
            }
          });
          //console.log("hello", sub);
          if (sub.length > 0) {
            item.sub_theme = sub;
            return item;
          }
          //return false;
        });
        /*  console.log(
          "feedback",
          feedback.filter((row) => !!row)
        ); */
        /*  if (!feedback) {
          return row;
        } else {
          return false;
        } */
        //return row;
        row.feedback = feedback.filter((row) => !!row);
        return row;
      });
      setExpandableData(response);
      //console.log("response", response);
    }
  }, [data]);

  let navigate = useNavigate();

  const noDataText = () => {
    return <span className='text-slate-400'>No data</span>;
  };

  const showStatusCell = (row) => {
    const status = row.status;
    if (!status) return noDataText();

    const statyStyle =
      status === UPDATE_STATUS
        ? "bg-red-200 text-red-500"
        : "bg-green-200 text-green-600";

    return (
      <>
        {/*  <p className={`h-[5rem] w-[5rem] pt-7 rounded-full ${statyStyle}`}></p> */}
        <p
          className={`h-[7rem] w-[7rem] pt-11 rounded-full font-semibold text-slate-600 ${statyStyle}`}
        >
          {status === "Pending" ? "In Progress" : "Completed"}
        </p>
      </>
    );
  };

  const getFormEditActionIcon = (row) => {
    if (actionType.includes("view")) {
      const disableClass = "opacity-30 disabled:cursor-not-allowed";
      const isDisable = false; // Add condition on for which button will be disabled
      const canEdit = row.status === UPDATE_STATUS; // Disable edit for submitted forms.

      return (
        <>
          <button
            type='button'
            // onClick={() => handleActionClick(row.status, row, VIEW_ACTION)}
            onClick={() => navigate(`/feedback/${row.objectId}`)}
            className={` px-1 py-2 bottom-8 leading-none rounded text-white font-semibold text-xs  ${btn_bg_color_hover} ${isDisable && disableClass
              }`}
            style={{ left: "120px", marginRight: "5px" }}
            disabled={isDisable}
          >
            {canEdit ? "Continue the self-assessment" : "View my answers"}
          </button>
          {
            <button
              onClick={() => handleGraphClick(row)}
              className={`w-[7rem] px-1 py-2 leading-none rounded text-white font-semibold text-xs ${btn_bg_color_hover} ${row.status !== SUBMIT_STATUS && disableClass
                }`}
              disabled={row.status !== SUBMIT_STATUS}
              style={{ right: "8px", marginRight: "5px" }}
            >
              View my results
            </button>
          }
          {
            // Delete feedback button
            <button
              type='button'
              onClick={() => handleDeleteFeedbackClick(row)}
              className={`w-[7rem] px-1 py-2  bottom-8 leading-none rounded text-white font-semibold text-xs bg-red-600 hover:bg-red-700`}
              style={{ left: "120px" }}
            >
              Delete my results
            </button>
          }
        </>
      );
    }
  };

  const displayDate = (value) => {
    const dateField = value.updated ? value.updated : value.created;

    const prefixText = value.updated ? "Updated on " : "Created on ";
    const datetext = moment(new Date(dateField)).format("DD/MM/YYYY");
    const timeText = moment(new Date(dateField)).format("HH:mm a");

    return (
      <div className='text-base text-slate-500 font-medium'>
        <span className='absolute top-2 right-0 pb-3 pt-4 pl-2 pr-10'>
          {prefixText}
          {datetext}
        </span>
        <span className='absolute top-8 right-0 pb-3 pt-4 pl-2 pr-10'>
          {`at ${timeText}`}
        </span>
      </div>
    );
  };

  const newFeedbackBtn = () => {
    return (
      <div className="sticky top-16 z-40 bg-gray-50">
        <button
          type="button"
          className={`text-white font-bold text-sm px-5 py-2.5 mr-2 mb-2 ${themeDefault.btn_bg_color} hover:${themeDefault.btn_bg_hover} focus:outline-none`}
          onClick={() => navigate(`/${WIZARD_ROUTE}`)}
        >
          {BTN_NEWFEEDBACK_TITLE}
        </button>
      </div>
    );
  };

  return (
    <div class='p-5 bg-gray-50'>
      {newFeedbackBtn()}
      <h1
        class={`text-xl font-medium bg-gray-50 ${themeDefault.text_color_dark} py-3 mb-4 sticky top-24 right-0 z-[1]`}
      >
        SELF-ASSESSMENT RESULTS AND POTENTIAL AREAS OF IMPROVEMENT FOR YOUR
        SPORT ORGANISATION
      </h1>
      {!expandableData ||
        (!expandableData.length && (
          <div
            className='card bg-base-100 shadow-xl mb-5 flex justify-center items-center text-lg font-semibold text-slate-500'
            style={{ minHeight: "150px" }}
          >
            No self-assessment started yet. Please click 'Start the
            self-assessment' to start!
          </div>
        ))}
      
            <div>
            {expandableData &&
              expandableData.map((singleValue, index) => {
                return (
                  <div
                    className="card bg-base-100 shadow-xl mb-5 flex flex-row transition ease-in-out hover:-translate-y-1 hover:scale-5 hover:bg-sky-50"
                    style={{ minHeight: "150px" }}
                  >
                    <div
                      className="relative left-7 p-3 mr-4 text-base text-slate-700"
                      style={{ margin: "auto" }}
                    >
                      {showStatusCell(singleValue)}
                    </div>
                    {displayDate(singleValue)}
                    <div className="card-body flex text-left pb-2">
                      {(singleValue.feedback || []).map(
                        (feedbackValue, feedBackIndex) => {
                          return (
                            <div>
                              <div className="ml-2 font-bold text-base text-slate-600">
                                {`PILLAR ${feedbackValue?.sequence}: ${feedbackValue?.title}`}
                              </div>
                              <div className="mt-1">
                                {feedbackValue.sub_theme.map(
                                  (subThemeTitle, subThemeIndex) => {
                                    //console.log("feedbackValue", subThemeTitle);
                                    return (
                                      <div className="pl-8 text-sm font-medium text-slate-500 leading-loose">
                                        {`Sub-Pillar ${subThemeTitle.sequence}: ${subThemeTitle.title}`}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                      {(!singleValue.feedback ||
                        singleValue.feedback.length === 0) && (
                        <span className="card-body font-bold text-lg text-slate-400">
                          Not answered !
                        </span>
                      )}
                      <div class="flex justify-end mt-auto">
                        <div class="bottom-0 right-0 pb-2">
                          {getFormEditActionIcon(singleValue)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
         
       
    </div>
  );
};

export default Card;
